@import '../../styles/index.scss';

.ShareWithContainer {
  width: 100%;
  min-height: 50vh;
  height: 50vh;
  overflow-y: visible;
  overflow-x: hidden;
  @include flex(column, flex-start, flex-start, nowrap);
  .ShareWithListContainer {
    width: 100%;
    @include flex(column, flex-start, flex-start, nowrap);
 
    .ShareWithHeaderContainer {
      width: 100%;
      .ShareWithCheckbox {
        color: $actionColor1;
      }
      span {
        color: $fontColor1;
        font-size: 1rem;
      }
      @include border(solid, 0px 0px 1px 0px, 0px, $fontColor1);
    }
  }
}
.ShareWithPublicAvailable {
  width: 100%;
  
  .ShareWithFooterContainer {
    width: 100%;
    @include flex(row, space-between, center, nowrap);
    button {
      background-color: $grayColor6;
      color: $actionColor1;
      @include border(solid, 1px, 5px, $actionColor1);
      padding: 5px 10px;
      opacity: 0.9;
      margin: 0px 5px;
      font-size: 1.5rem;
      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
  } 
}

.ShareWithItemCheckboxTitle {
  color: $fontColor1;
  font-size: 1rem;
}
