@import '../../styles/index.scss';

.UserContainer {
  width: 100%;
}

.UserG1Container,
.UserG2Container,
.UserG3Container {
  @include G50Container();
  color: $fontColor1;
  font-size: 1rem;
  box-sizing: border-box;
}
