@import '../../styles/index.scss';

.UserListContainer {
  width: 100%;
  padding: 20px;
}

.UsersListHeaderContainer {
  width: 100%;
  @include flex(column, center, flex-start, nowrap);
}

.UsersListHeaderTitle {
  width: 100%;
  padding: 10px 0px;
}

.UsersListHeaderAction {
  width: 100%;
  @include flex(row, space-between, center, nowrap);
  div {
    @include flex(row, flex-start, center, nowrap);
    padding: 10px 0px;

    button {
      margin-right: 10px;
      font-size: 1rem;
      background-color: $grayColor8;
      color: $grayColor2;
      padding: 5px 10px;
      opacity: 0.95;
      @include border(solid, 1px, 5px, $grayColor8);
      
      &:hover {
        color: $fontColor1;
        opacity: 1;
        cursor: pointer;
        @include border(solid, 1px, 5px, $actionColor1);
      }
    }

  }
}

.UsersListHeaderActionActiveBtn {
  color: $fontColor1 !important;
  opacity: 1 !important;
  cursor: pointer;
  border: solid 1px $actionColor1 !important;
  border-radius: 5px !important;
}

.UserListPagination {
  width: 100%;
  @include flex(row, flex-end, center, nowrap);
}
