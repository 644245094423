@import '../../styles/index.scss';

.ShareButton {
  @include border(solid, 0px, 0px, transparent);
  background-color: transparent;
  opacity: 0.9;
  div {
    @include flex(row, center, center, nowrap);
    span {
      color: $fontColor1;
      font-size: 1rem;
      margin-left: 5px;
    }
  }
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.ShareModalBodyContent {
  width: 100%;
  padding: 20px 0px ;
  li {
    padding: 5px 0px;
    button {
      padding: 5px 10px;
      color: $fontColor1;
      background-color: transparent;
      border-style: none;
      margin-left: 10px;
      &:hover {
        cursor: pointer;
        color: $actionColor1;
      }
    }
  }
}