// flexbox mixin
@mixin flex(
  $fd: row, 
  $jc: center, 
  $ai: center,
  $fw: nowrap
) {
  display: flex;
  flex-direction: $fd;
  justify-content: $jc;
  align-items: $ai;
  flex-wrap: $fw;
}

// border mixin
@mixin border(
  $style: none, 
  $width: 0px, 
  $radius: 0px, 
  $color: white
) {
  border-style: $style;
  border-width: $width;
  border-radius: $radius;
  border-color: $color;
}

// font mixin
@mixin font(
  $color: white,
  $size: 16px,
  $weight: normal,
  $style: normal,
  $letterSpacing: 0px,
) {
  color: $color;
  font-size: $size;
  font-weight: $weight;
  font-style: $style;
  letter-spacing: $letterSpacing;
}

// background image mixin
@mixin backgroundImg(
  $size: contain,
  $position: center,
  $repeat: no-repeat,
  $clip: border-box,
  $attachment: initial,
) {
  background-position: $position;
  background-repeat: $repeat;
  background-size: $size;
  background-clip: $clip;
  background-attachment: $attachment;
}