$blockH: 60vh;

// Home Grid 50%
@mixin G50Container {
  background-color: $grayColor6;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  height: 100%;
  @include flex(column, flex-start, center, nowrap);
}

@mixin heightGrid {
  max-height: $blockH;
  height: $blockH;
}

@mixin heightGridMin {
  min-height: $blockH;
  height: 100%;
}

// Home Grid 50%
@mixin G50BodyContainer {
  width: 100%;
  height: 100%;
  @include flex(column, flex-start, flex-start, nowrap);
}

// Home Grid 50%
@mixin G50HeaderContainer { 
  // height: 15%;
  width: 100%;
  @include flex(row, space-between, center, nowrap);
}

// Home Grid 50%
@mixin G50HeaderTitleContainer {
  h3 {
    color: red;
    font-weight: bold;
    font-size: 3rem !important;
  }
}

@mixin CountCheckboxInBtn {
  color: $grayColor6 !important;
  background-color: white;
  padding: 2px 7.5px;
  @include border(none, 0px, 5px, transparent)
}
