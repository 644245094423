@import '../../styles/index.scss';

.MenuListCompositionContainer {
  @include flex(row, center, center, nowrap);
  z-index: $zIndexLevel999;
  button {
    width: 100%;
    @include flex(row, space-between, center, nowrap);
    span {
      color: $fontColor1;
      margin-right: 10px;
    }
  }
}

.MenuListCompositionMenuContainer {
  background-color: $grayColor6;
  @include border(solid, 1px, 5px, $grayColor4);
}

.MenuListCompositionItem {
  @include border();
  @include flex(column, center, center, nowrap);
  @include font($fontColor1, 1rem);
  width: 100%;
  background-color: transparent;  
  &:hover {
    background-color: $actionColor1;
    cursor: pointer;
  }  
}