@import '../../styles/index.scss';

.HomeContainer {
  width: 100%;
}

.HomeG1Container,
.HomeG2Container,
.HomeG3Container {    
  width: 100%;  
  color: $fontColor1;
  box-sizing: border-box;
}

.HomeG3Container {
  .HomeG3ContainerTitle {
    width: 100%;
    text-align: center;
    h3 {
      padding: 10px 0 0px 0;
      font-size: 1.5rem;
    }
  }
}