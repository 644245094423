@import '../../styles/index.scss';

.TopicContainer {
  width: 100%;
  min-height: 80vh;
  padding: 20px 40px;
  border-radius: 5px;
  background-color: $grayColor6;
}

.TopicHeaderContainer {
  width: 100%;
  height: 220px;
  padding: 20px 0px;
  @include flex(row, space-between, flex-end, nowrap);
  @include border(solid, 0px 0px 1px 0px, 0px, $grayColor5);
}

.TopicHeaderImgContainer {
  @include flex(row, flex-start, flex-end, nowrap);

  .TopicHeaderImg {
    width: 150px;
    height: 150px;
    border-radius: 5px;
    @include backgroundImg(cover);
  }

  .TopicHeaderTextContainer {
    height: 100%;
    @include flex(column, flex-end, flex-start, nowrap);
    color: $fontColor1;
    padding: 0px 10px;
    .TopicHeaderTextTitle {
      width: 75%;
      @include flex(row, flex-start, center, nowrap);
      margin-bottom: 10px;
      span {
        margin-left: 10px;
        font-size: 1.5rem;
        font-weight: bold;
      }
      svg {
        width: 80px!important;
        height: 80px!important;
      }
      image, svg {
        width: 55px;
        height: 55px;
      }
    }    
    .TopicHeaderTextAffirmations {
      padding: 5px 10px;
      background-color: $grayColor7;
      border-radius: 5px;
      span {
        font-size: 1rem;
        color: $grayColor3;
      }
    }
    
  }
}

.TopicHeaderShareContainer {
  padding: 0px 10px;
}

.TopicHeaderTextDescription {
  padding: 10px;
  width: 90%;
  h6 {
    font-size: 0.8rem;
    color: $fontColor1;
  }
}