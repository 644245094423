@import '../../styles/index.scss';

.AffirmationsByTopicsContainer {
  width: 100%;
}

.AffirmationsByTopicsTitleContainer {
  width: 100%;
  @include flex(row, flex-start, center, nowrap);
  padding-bottom: 10px;
  margin-top: 20px;
  .AffirmationsByTopicsTitleTopic {
    color: $fontColor1;
    font-style: italic;
    font-size: 1.25rem;
    margin-left: 10px;
  }
}

.AffirmationsByTopicsHeaderContainer {
  width: 100%;
  @include flex(column, center, flex-start, nowrap);
  .AffirmationsByTopicsActionContainer {
    width: 100%;
    padding: 10px 0px;
    @include flex(row, flex-start, center, wrap);
    h5 {
      color: $grayColor3;
    }
    .AffirmationsByTopicsCheckbox {
      color: $actionColor1;
    }
    div button {
      background-color: transparent;
      @include border(solid, 1px, 5px, $grayColor3);
      color: $grayColor3;
      padding: 5px 15px;
      margin-left: 10px;
      font-size: 1rem;
      opacity: 0.8;
      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
  .AffirmationsByTopicsBoxesContainer {
    width: 100%;
    padding: 10px 0px;
    .affirmationsByTopicsButton {
      background-color: transparent;
      border-style: none;
      width: 100%;
      height: 100%;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.AffirmationsByTopicsListContainer {
  width: 100%;
}

.affirmationsByTopicsCaruselContainer {
  @include flex(row, flex-start, center, nowrap);
}

.affirmationsByTopicsCaruselBtn {
  border-style: none;
  background-color: transparent;
  color: red;
  padding: 5px 15px;
}