@import '../../styles/index.scss';

.InvitePeopleContainer {
  width: 100%;
  padding: 10px 0px;
  @include flex(column, center, flex-start, nowrap);
  textarea {
    width: 100%;
    height: 100px;
    resize: none;
    background-color: $grayColor6;
    color: $fontColor1;
    @include border(solid, 1px, 5px, transparent);
    padding: 5px;
    font-size: 16px;
  }
  input {
    width: 100%;
    background-color: $grayColor7;
    @include border(solid, 1px, 5px, transparent);
    padding: 10px;
    color: $fontColor1;
    font-size: 1rem;
  }

  .InvitePeopleListTitle {
    color: #fff;
    margin-top: 15px;
  }

  .InvitePeopleDivider {
    background-color: $grayColor5;
    width: 100%;
  }

  .InvitePeopleList {
    width: 100%;
    max-height: 500px;
    overflow: auto;

    .InvitePeopleListItem {
      color: $grayColor3;
      background-color: $grayColor5;
      border-radius: 5px;
      margin-bottom: 10px;
    }
  }

  .InvitePeopleFooterContainer {
    width: 100%;
    @include flex(row, space-between, center, nowrap);
    padding-top: 20px;
    .InvitePeopleLinkContainer {
      background-color: transparent;
      border-style: none;
      @include flex(row, flex-start, center, nowrap);
      opacity: 0.9;
      span {
        color: $fontColor1;
        margin-left: 10px;
      }
      &:hover{
        opacity: 1;
        cursor: pointer;
        span {
          color: $actionColor1;
        }
      }
    }
    button {
      background-color: $grayColor6;
      @include border(solid, 1px, 5px, $actionColor1);
      padding: 5px 15px;
      opacity: 0.9;
      color: $actionColor1;
      &:hover{
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}

.inputError {
  width: 100%;
  @include flex(row, flex-end, center, nowrap);
  span {
    font-size: 13px;
    color: $actionColor4;
  }
}

