@import '../../styles/index.scss';

.AffirmationsContainer {
  @include flex(row, center, center, nowrap);  
  width: 100%;
}

.AffirmationsContentContainer {
  width: 100%;
  border-radius: 5px;
  padding: 15px;
  background-color: $grayColor6;
  min-height: 50vh;
}

.AffirmationsHeaderContainer {
  padding: 5px 0px;
  @include border(solid, 0px 0px 1px 0px, 0px, $grayColor5);
}
.AffirmationsHeaderTitleContainer {
  padding: 5px 0px;
}
.AffirmationsHeaderTabsContainer {
  @include flex(row, space-between, center, nowrap);
  padding: 5px 0px;
  div {
    button {
      margin-right: 20px;
      background-color: transparent;
      @include border(solid, 0px, 0px, transparent);
      opacity: 0.9;
      span {
        color: $grayColor3;
        font-size: 1.25rem;
      }
      &:hover {
        cursor: pointer;
        opacity: 1;
        span {
          color: $fontColor1;
        }
      }      
    }
  }
}

.AffirmationsBtnSelected {
  opacity: 1 !important;
  span {
    color: $fontColor1 !important;
  }
}

.AffirmationsPacks {
  width: 100%;
}
