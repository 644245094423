@import '../../styles/index.scss'; // global styles

@import './blog'; // blog styles

.global {
    overflow-x: hidden;
    overflow-y: hidden;
}

a {
    text-decoration: none;
    color: white;
}

a:hover {
    color: #15E7BC;
}

a:active {
    color: #15E7BC;
}

.MuiGrid-grid-xs-12 {
    -webkit-flex: 1 0 auto !important;
}

.MuiGrid-item {
    width: 100% !important;
}
