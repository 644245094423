@import '../../styles/index.scss';

.AffirmationChartContainer {
  width: 100%;  
  button {
    z-index: $zIndexLevel998;
    @include border(solid, 1px, 5px, transparent);
    @include flex(column, center, flex-start, nowrap);
    width: 100%;  
    padding: 5px 10px;
    background-color: $grayColor5;
    margin-bottom: 10px;
    &:hover, &:focus, &:active {
      @include border(solid, 1px, 5px, $fontColor1);
      cursor: pointer;
    }
  }
}

.AffirmationChartBtnActive {
  background-color: $grayColor4 !important;
}

.AffirmationChartText {
  color: $fontColor1;
  font-size: 1rem;
}

.AffirmationChartChartContainer {
  width: 100%;
  padding: 2.5px 0px; 
  @include flex(row, flex-start, center, nowrap);
}

.AffirmationChartChart {
  height: 7.5px;
  max-height: 7.5px;  
  border-radius: 0px 5px 5px 0px;
  margin-right: 5px;
}

.AffirmationChartChartText {
  color: $fontColor1;
  font-size: 1rem;
}
