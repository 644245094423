@import '../../styles/index.scss';

.NewAffirmationContainer {
  width: 100%;
  @include flex(column, space-between, flex-start, nowrap);
  background-color: $grayColor5;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.NewAffirmationSTop {
  @include flex(row, space-between, center, nowrap);
  width: 100%;
  .NewAffirmationS1 {
    word-break: break-all;
    @include flex(row, flex-start, center, nowrap);
    width: 60%;
    h4 {
      font-size: 16px;
      color: $fontColor1;
    }
    .NewAffirmationCheckbox {
      color: $actionColor1 !important;
    }
    .NewAffirmationEditTitleContainer {
      width: 90%;
      input {
        width: 60%;
        border-style: none;
        border-radius: 5px;
        color: $fontColor1;
        background-color: $grayColor7;
        padding: 5px 10px;
        font-size: 16px;
        margin-right: 10px;
      }
      button {
        width: 100px;
        color: $actionColor1;
        @include border(solid, 1px, 5px, $actionColor1);
        padding: 6px 15px;
        background-color: transparent;
        margin-right: 20px;
        font-size: 16px;
        opacity: 0.8;
        &:hover {
          cursor: pointer;
          opacity: 1;
        }
      }
    }
  }

  .NewAffirmationS2 {
    @include flex(row, space-between, center, nowrap);

    .NewAffirmationS2RemoveBtn {
      color: $actionColor2;
      @include border(solid, 1px, 5px, $actionColor2);
      @include flex(row, center, center, nowrap);
      padding: 6px 15px;
      background-color: transparent;
      margin-right: 20px;
      font-size: 16px;
      opacity: 0.8;
      line-height: 1.43;
      font-weight: 400;

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }

    .NewAffirmationS2AddBtn {
      color: $actionColor1;
      @include border(solid, 1px, 5px, $actionColor1);
      @include flex(row, center, center, nowrap);
      padding: 6px 15px;
      background-color: transparent;
      margin-right: 20px;
      font-size: 16px;
      opacity: 0.8;
      line-height: 1.43;
      font-weight: 400;

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }

    .NewAffirmationS2DeleteBtn {
      @include flex(row, space-between, center, nowrap);

      button {
        color: $actionColor2;
        background-color: transparent;
        cursor: pointer;
        padding: 6px 15px;
        margin-right: 20px;
        font-size: 16px;
        opacity: 0.8;
        text-transform: initial;
        line-height: 1.43;
        font-weight: 400;

        @include border(solid, 1px, 5px, $actionColor2);
        @include flex(row, center, center, nowrap);

        &:hover {
          cursor: pointer;
          opacity: 1;
        }

        input {
          display: none;
          overflow: hidden;
        }
      }
    }

    .NewAffirmationS2TopicsBtn {
      // width: 50%;
      button {
        width: 150px;
        border-radius: 5px;
        background-color: $grayColor7;
      }
    }

    .NewAffirmationS2Icons {
      @include flex(row, space-between, center, nowrap);
      padding: 0px 10px;
      .NewAffirmationMenuActions {
        div ul {
          @include flex(column, flex-start, center, nowrap);
          button {
            padding: 0px;
            width: 100%;
            div {
              padding: 5px 15px;
            }
          }
        }
      }
      button {
        border-style: none;
        background-color: transparent;
        opacity: 0.8;
        &:hover {
          opacity: 1;
          cursor: pointer;
        }
      }
    }
  }
}

.NewAffirmationSBottom {
  width: 100%;
  @include flex(row, flex-start, center, nowrap);
}

.NewAffirmationCountCheckbox {
  @include CountCheckboxInBtn();
}
