@import '../../styles/index.scss';

.ClientFlowContainer {
  @include G50Container(); 
  @include heightGrid();
  padding: 20px;
  // box-sizing: border-box;
}

.ClientFlowBodyContainer {
  @include G50BodyContainer();
}

.ClientFlowHeaderContainer { 
  @include G50HeaderContainer();
}

.ClientFlowHeaderTitleContainer {
  width: 50%;
  @include G50HeaderTitleContainer(); 
}

.ClientFlowHeaderChartsS1Container {
  width: 50%;
  max-width: 390px;
  height: 80%;
  @include flex(row, space-around, center, nowrap);
}

.ClientFlowBodyChartContainer {
  height: 85%;
  max-height: 85%;
  width: 100%;
  @include flex(column, flex-start, center, nowrap);
}

.ClientFlowChartS2Container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  @include flex(row, center, center, nowrap);
}

.ClientFlowChartS3Container {
  height: 50%;
  max-height: 50%;
  width: 100%;
  // padding: 0px 25px;
  margin-left: 20px;
  @include flex(row, space-between, center, nowrap);
}
