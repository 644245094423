@import '../../styles/index.scss';


.PackContainer {
  padding: 10px;
  
  .PackImgContainer {
    position: relative;
    width: 100%;
    height: 35vh;
    max-height: 300px;
    max-width: 300px;
    margin: 0 auto;
    border-radius: 5px;
    @include backgroundImg(cover);
  }

  .PackImgContainerMin {
    position: relative;
    width: 100%;
    height: 15vh;
    max-height: 15vh;
    border-radius: 5px;
    @include backgroundImg(cover);
  }
}
.PackBodyContainer {
  @include flex(column, flex-start, center, nowrap);
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;  
  span {
    color: $fontColor1;
    text-align: center;
    font-size: 1rem;
  }

  .TopicCheckbox {
    color: $actionColor1;
  }
  div {
    span {
      color: $fontColor1;
      text-align: center;
      font-size: 1rem;
    }
  }

  .TopicIconContainer {
    width: 100%;
    height: 80%;
    position: absolute;
    left: 0;
    bottom: 20px;
    right: 0;
    @include flex(column, center, center, nowrap); 
  }

  .TopicIconContainer image {
    width: 55px;
  }

  .TopicHeaderContainer {
    width: 100%;
    padding: 0px 10px;
    @include flex(row, flex-start, center, nowrap); 
    margin-top: 10px;
    span {
      color: $actionColor1;
      text-align: center;
    }
    h6 {
      color: $fontColor1;
      font-size: 1rem;
    }
  }   
}
