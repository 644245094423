@import '../../../styles/index.scss';

.BoxModel3Container {
  background-color: $grayColor5;
  width: 100%;
  height: 20vh;
  @include flex(row, space-between, center, nowrap);
  padding: 10px;
  border-radius: 5px;

  .BoxModel3ValueContainer {
    width: 50%;
    height: 100%;
    padding: 20px;
    @include flex(column, center, center, nowrap);
    span {
      width: 100%;
        font-size: 2.5rem;
        font-weight: bold;
        color: $actionColor1;
    }
  }
  
  .BoxModel3InfoContainer {
    width: 50%;
    height: 100%;
    @include flex(column, center, flex-start, nowrap);

    .BoxModelInfoTitle {
      span {
        width: 100%;
        font-size: 1.5rem;
        font-weight: bold;
        color: $grayColor3;
      }
    }
    
    .BoxModel3InfoText {
      span {
        width: 100%;
        color: $grayColor3;
        font-size: 1.25rem;
      }
    }
  }
}

