@import '../../styles/index.scss';

.ItemCheckboxContainer {
  width: 100%;
  @include flex(row, flex-start, center, nowrap);
  padding: 5px 0px;

  .ItemCheckboxCheck {
    color: $actionColor1 !important;
  }

  .ItemCheckboxTitle {
    margin-left: 10px;
  }
}

