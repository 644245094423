@import "../../styles/index.scss";

.ProfileContainer {
  background-color: $grayColor6;
  border-radius: 5px;
  padding: 20px;
}

.ProfileTitleContainer {
  padding: 10px;
  width: 100%;
  @include flex(column, center, flex-start, nowrap);
  margin-bottom: 10px;
}

.ProfileGridContainer {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  @include flex(column, center, flex-start, nowrap);
  div {
    width: 100%;
    @include flex(column, center, flex-start, nowrap);
    margin-bottom: 20px;
    label {
      color: $fontColor1;
      font-size: 1.25rem;
      padding: 5px 0px;
    }
    .ProfileLabelDisabled {
      color: $grayColor3;
    }
    input {
      width: 100%;
      background-color: $grayColor7;
      @include border(solid, 1px, 5px, transparent);
      padding: 10px;
      color: $fontColor1;
      font-size: 1.25rem;
    }
    input[disabled] {
      background-color: $grayColor5;
      color: $grayColor3;
    }
    textarea {
      width: 100%;
      background-color: $grayColor7;
      @include border(solid, 1px, 5px, transparent);
      padding: 10px;
      color: $fontColor1;
      font-size: 1.25rem;
      resize: none;
    }
  }
}

.ProfileGridAvatarContainer {
  padding: 10px;
  background-color: $grayColor5;
  width: 100%;
  height: 45vh;
  @include flex(column, center, center, nowrap);
  border-radius: 5px;
}

.ProfileAvatarContainer {
  width: 100%;
  @include flex(column, center, center, nowrap);
  margin-bottom: 10px;
}

.ProfileBtnsContainer {
  width: 100%;
  @include flex(column, center, center, nowrap);
  button {
    background-color: transparent;
    @include border(solid, 1px, 5px, transparent);
    color: $fontColor1;
    font-size: 1.25rem;
    padding: 5px 20px;
    margin-bottom: 5px;
    &:hover {
      @include border(solid, 1px, 5px, $grayColor3);
    }
    input {
      display: none;
      overflow: hidden;
    }
  }
}

.ProfileFooterContainer {
  width: 100%;
  @include flex(row, flex-end, center, nowrap);
  margin-top: 10px;
  button {
    margin-left: 10px;
    padding: 10px 15px;
    background-color: $grayColor7;
    color: $fontColor1;
    font-size: 1.25rem;
    @include border(solid, 1px, 5px, transparent);
    &:hover {
      @include border(solid, 1px, 5px, $actionColor1);
    }
  }
}

.ItemCheckboxContainer {
  width: 100%;
  @include flex(row, flex-start, center, nowrap);
  padding: 5px 0px;

  .ItemCheckboxCheck {
    color: $actionColor1 !important;
  }

  .ItemCheckboxTitle {
    margin-left: 10px;
  }
}
