@import '../../styles/index.scss';

.ClientContainer {
  width: 100%;
  flex: 1;
}

.ClientG1Container {
  background-color: $grayColor6;
  border-radius: 5px;
  width: 100%;
  @include flex(row, flex-start, center, wrap);
  margin-bottom: 20px;
}
