/* Table of Contents
/* ------------------------------------------------------------

This is a development CSS file which is built to a minified
production stylesheet in assets/built/screen.css

2.  Layout
3.  Special Templates
4.  Site Header
  4.1 Home header
  4.2 Archive header
5.  Site Navigation
6.  Post Feed
7.  Single Post
  7.1. Post Byline
  7.2. Members Subscribe Form
  7.4. Related Posts
  7.5. Koenig Styles
8.  Author Template
9.  Error Template
11. Site Footer
12. Dark Mode

*/

/* 1. Global - Set up the things
/* ---------------------------------------------------------- */

:root {
    /* Colours */
    --color-green: #a4d037;
    --color-yellow: #fecd35;
    --color-red: #f05230;
    --color-darkgrey: #15171A;
    --color-midgrey: #738a94;
    --color-lightgrey: #c5d2d9;
    --color-wash: #e5eff5;
    --color-darkmode: #151719;

    /*
    An accent color is also set by Ghost itself in
    Ghost Admin > Settings > Brand

    --ghost-accent-color: {value};

    You can use this variale throughout your styles
     */

    /* Fonts */
    --font-sans-serif: -apple-system, BlinkMacSystemFont, 'Biotif', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    --font-serif: Georgia, Times, serif;
    --font-mono: Menlo, Courier, monospace;

}

.blog-container {
  font-size: 10px;
  line-height: 26px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0;
  text-rendering: optimizeLegibility;

  /* 1. RESET */
  /* Reset
  /* ---------------------------------------------------------- */
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      font-size: 100%;
      vertical-align: baseline;
  }
  ol,
  ul {
      list-style: none;
  }
  blockquote,
  q {
      quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
      content: "";
      content: none;
  }
  table {
      border-spacing: 0;
      border-collapse: collapse;
  }
  img {
      display: block;
      max-width: 100%;
      height: auto;
  }
  html {
      box-sizing: border-box;
      font-family: sans-serif;

      -ms-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%;
  }
  *,
  *:before,
  *:after {
      box-sizing: inherit;
  }
  a {
      background-color: transparent;
  }
  a:active,
  a:hover {
      outline: 0;
  }
  b,
  strong {
      font-weight: bold;
  }
  i,
  em,
  dfn {
      font-style: italic;
  }
  h1 {
      margin: 0.67em 0;
      font-size: 2em;
  }
  small {
      font-size: 80%;
  }
  sub,
  sup {
      position: relative;
      font-size: 75%;
      line-height: 0;
      vertical-align: baseline;
  }
  sup {
      top: -0.5em;
  }
  sub {
      bottom: -0.25em;
  }
  img {
      border: 0;
  }
  svg:not(:root) {
      overflow: hidden;
  }
  mark {
      background-color: #fdffb6;
  }
  code,
  kbd,
  pre,
  samp {
      font-family: monospace, monospace;
      font-size: 1em;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
      margin: 0; /* 3 */
      color: inherit; /* 1 */
      font: inherit; /* 2 */
  }
  button {
      overflow: visible;
      border: none;
  }
  button,
  select {
      text-transform: none;
  }
  button,
  html input[type="button"],
  /* 1 */
  input[type="reset"],
  input[type="submit"] {
      cursor: pointer; /* 3 */

      -webkit-appearance: button; /* 2 */
  }
  button[disabled],
  html input[disabled] {
      cursor: default;
  }
  button::-moz-focus-inner,
  input::-moz-focus-inner {
      padding: 0;
      border: 0;
  }
  input {
      line-height: normal;
  }
  input:focus {
      outline: none;
  }
  input[type="checkbox"],
  input[type="radio"] {
      box-sizing: border-box; /* 1 */
      padding: 0; /* 2 */
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
      height: auto;
  }
  input[type="search"] {
      box-sizing: content-box; /* 2 */

      -webkit-appearance: textfield; /* 1 */
  }
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration {
      -webkit-appearance: none;
  }
  legend {
      padding: 0; /* 2 */
      border: 0; /* 1 */
  }
  textarea {
      overflow: auto;
  }
  table {
      border-spacing: 0;
      border-collapse: collapse;
  }
  td,
  th {
      padding: 0;
  }

  /* ==========================================================================
    Base styles: opinionated defaults
    ========================================================================== */
  ::selection {
      text-shadow: none;
      background: #daf2fd;
  }

  hr {
      position: relative;
      display: block;
      width: 100%;
      margin: 2.5em 0 3.5em;
      padding: 0;
      height: 1px;
      border: 0;
      border-top: 1px solid #f0f0f0;
  }

  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
      vertical-align: middle;
  }

  fieldset {
      margin: 0;
      padding: 0;
      border: 0;
  }

  textarea {
      resize: vertical;
  }

  ::not(.gh-content) p,
  ::not(.gh-content) ul,
  ::not(.gh-content) ol,
  ::not(.gh-content) dl,
  ::not(.gh-content) blockquote {
      margin: 0 0 1.5em 0;
  }

  ol,
  ul {
      padding-left: 1.3em;
      padding-right: 1.5em;
  }

  ol ol,
  ul ul,
  ul ol,
  ol ul {
      margin: 0.5em 0 1em;
  }

  ul {
      list-style: disc;
  }

  ol {
      list-style: decimal;
  }

  ul,
  ol {
      max-width: 100%;
  }

  li {
      padding-left: 0.3em;
      line-height: 1.6em;
  }

  li + li {
      margin-top: 0.5em;
  }

  dt {
      float: left;
      margin: 0 20px 0 0;
      width: 120px;
      color: #daf2fd;
      font-weight: 500;
      text-align: right;
  }

  dd {
      margin: 0 0 5px 0;
      text-align: left;
  }

  blockquote {
      margin: 1.5em 0;
      padding: 0 1.6em 0 1.6em;
      border-left: #daf2fd;
  }

  blockquote p {
      margin: 0.8em 0;
      font-size: 1.2em;
      font-weight: 300;
  }

  blockquote small {
      display: inline-block;
      margin: 0.8em 0 0.8em 1.5em;
      font-size: 0.9em;
      opacity: 0.8;
  }
  /* Quotation marks */
  blockquote small:before {
      content: "\2014 \00A0";
  }

  blockquote cite {
      font-weight: bold;
  }
  blockquote cite a {
      font-weight: normal;
  }

  a {
      color: #15171A;
      text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
      margin-top: 0;
      line-height: 1.15;
      font-weight: 600;
      text-rendering: optimizeLegibility;
      letter-spacing: -0.01em;
  }

  h1 {
      margin: 0 0 0.5em 0;
      font-size: 4.8em;
      font-weight: 700;
      letter-spacing: -0.015em;
  }
  @media (max-width: 600px) {
      h1 {
          font-size: 2.8em;
      }
  }

  h2 {
      margin: 1.5em 0 0.5em 0;
      font-size: 2.8em;
      font-weight: 700;
  }
  @media (max-width: 600px) {
      h2 {
          font-size: 2.3em;
      }
  }

  h3 {
      margin: 1.5em 0 0.5em 0;
      font-size: 2.4em;
      font-weight: 600;
  }
  @media (max-width: 600px) {
      h3 {
          font-size: 1.7em;
      }
  }

  h4 {
      margin: 1.5em 0 0.5em 0;
      font-size: 2.2em;
  }

  h5 {
      margin: 1.5em 0 0.5em 0;
      font-size: 2em;
  }

  h6 {
      margin: 1.5em 0 0.5em 0;
      font-size: 1.8em;
  }

  /* 1. END RESET */
  /* 2. Layout - Page building blocks
  /* ---------------------------------------------------------- */

  .viewport {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .viewport > main {
    flex-grow: 1;
  }

  /* Full width page blocks */
  .outer {
    position: relative;
    padding: 0 4vmin;
  }

  /* Centered content container blocks */
  .inner {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
  }


  /* 4. Site Header
  /* ---------------------------------------------------------- */

  .site-header {
    position: relative;
    color: #fff;
    background: var(--ghost-accent-color);
  }

  .site-header-cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .site-header-content {
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6vw 3vw;
    min-height: 200px;
    max-height: 340px;
    text-align: center;
  }

  .site-title {
    z-index: 10;
    margin: 0 0 0.15em;
    padding: 0;
  }

  .site-logo {
    max-height: 55px;
  }

  .site-header-content p {
    z-index: 10;
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.2em;
    opacity: 0.8;
  }

  @media (max-width: 600px) {
    .site-header-content p {
        max-width: 80vmin;
        font-size: 1.8em;
    }
  }

  /* 4.1 Home header
  /* ---------------------------------------------------------- */

  .site-home-header {
    position: relative;
    z-index: 1000;
    overflow: hidden;
  }

  .site-header-content {
    padding: 18vmin 4vmin;
    font-size: 2.5em;
    font-weight: 400;
    color: #fff;
    background: var(--ghost-accent-color);
  }



  /* 5. Site Navigation
  /* ---------------------------------------------------------- */

  .gh-head {
    padding: 1vmin 4vmin;
    font-size: 1.6em;
    line-height: 1.3em;
    color: #fff;
    background: var(--ghost-accent-color);
  }

  .gh-head a {
    color: inherit;
    text-decoration: none;
  }

  .gh-head-inner {
    display: grid;
    grid-gap: 2.5vmin;
    grid-template-columns: auto auto 1fr;
    grid-auto-flow: row dense;
  }


  /* Brand
  /* ---------------------------------------------------------- */

  .gh-head-brand {
    display: flex;
    align-items: center;
    height: 40px;
    max-width: 200px;
    text-align: center;
    word-break: break-all;
  }

  .gh-head-logo {
    display: block;
    padding: 10px 0;
    font-weight: 700;
    font-size: 2em;
    line-height: 1.2em;
    letter-spacing: -0.02em;
  }

  .gh-head-logo img {
    max-height: 26px;
  }


  /* Primary Navigation
  /* ---------------------------------------------------------- */

  .gh-head-menu {
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  .gh-head-menu .nav {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .gh-head-menu .nav li {
    margin: 0 2.5vmin 0 0;
    padding: 0;
  }

  .gh-head-menu .nav a {
    display: inline-block;
    padding: 5px 0;
    opacity: 0.8
  }

  .gh-head-menu .nav a:hover {
    opacity: 1;
  }


  /* Secondary Navigation
  /* ---------------------------------------------------------- */

  .gh-head-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    text-align: right;
  }

  .gh-head-actions-list {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
  }

  .gh-head-actions-list a:not([class]) {
    display: inline-block;
    margin: 0 0 0 1.5vmin;
    padding: 5px 0;
  }

  .gh-social {
    margin: 0 1.5vmin 0 0;
  }

  .gh-social a {
    opacity: 0.8
  }

  .gh-social a + a {
    margin-left: 0.8em;
  }

  .gh-social a:hover {
    opacity: 1;
  }

  .gh-social svg {
    height: 22px;
    width: 22px;
    fill: #fff;
  }

  .gh-social-facebook svg {
    height: 20px;
    width: 20px;
  }

  a.gh-head-button {
    display: block;
    padding: 8px 15px;
    color: var(--color-darkgrey);
    font-weight: 500;
    letter-spacing: -0.015em;
    font-size: 1.5em;
    line-height: 1em;
    background: #fff;
    border-radius: 30px;
  }


  /* Mobile Menu Trigger
  /* ---------------------------------------------------------- */

  .gh-burger {
    position: relative;
    display: none;
    cursor: pointer;
  }

  .gh-burger-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
  }

  .gh-burger-inner {
    width: 100%;
    height: 100%;
  }

  .gh-burger-box::before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    content: '';
    width: 100%;
    height: 1px;
    background: currentcolor;
    transition: transform 300ms cubic-bezier(.2,.6,.3,1), width 300ms cubic-bezier(.2,.6,.3,1);
    will-change: transform, width;
  }

  .gh-burger-inner::before,
  .gh-burger-inner::after {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    content: '';
    width: 100%;
    height: 1px;
    background: currentcolor;
    transition: transform 250ms cubic-bezier(.2,.7,.3,1), width 250ms cubic-bezier(.2,.7,.3,1);
    will-change: transform, width;
  }

  .gh-burger-inner::before {
    transform: translatey(-6px);
  }
  .gh-burger-inner::after {
    transform: translatey(6px);
  }

  body:not(.gh-head-open) .gh-burger:hover .gh-burger-inner::before {
    transform: translatey(-8px);
  }
  body:not(.gh-head-open) .gh-burger:hover .gh-burger-inner::after {
    transform: translatey(8px);
  }

  .gh-head-open .gh-burger-box::before {
    width: 0;
    transform: translatex(19px);
    transition: transform 200ms cubic-bezier(.2,.7,.3,1), width 200ms cubic-bezier(.2,.7,.3,1);
  }

  .gh-head-open .gh-burger-inner::before {
    width: 26px;
    transform: translatex(6px) rotate(135deg);
  }

  .gh-head-open .gh-burger-inner::after {
    width: 26px;
    transform: translatex(6px) rotate(-135deg);
  }


  /* Mobile Menu
  /* ---------------------------------------------------------- */
  /* IDs needed to ensure sufficient specificity */

  @media (max-width: 900px) {
    .gh-burger {
        display: inline-block;
    }
    #gh-head {
        transition: all 0.4s ease-out;
        overflow: hidden;
    }
    #gh-head .gh-head-inner {
        height: 100%;
        grid-template-columns: 1fr;
    }
    #gh-head .gh-head-brand {
        position: relative;
        z-index: 10;
        grid-column-start: auto;
        max-width: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        user-select: none;
    }
    .home-template #gh-head .gh-head-brand {
        justify-content: flex-end;
    }
    #gh-head .gh-head-menu {
        align-self: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 0 0 10vh 0;
        font-weight: 300;
        font-size: 3.6em;
        line-height: 1.1em;
    }
    #gh-head .gh-head-menu .nav li {
        margin: 5px 0;
    }
    #gh-head .gh-head-menu .nav a {
        padding: 8px 0;
    }
    #gh-head .gh-head-menu .nav {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #gh-head .gh-head-actions {
        padding: 20px 0;
        justify-content: center;
        text-align: left;
    }
    #gh-head .gh-head-actions a {
        margin: 0 10px;
    }
    /* Hide collapsed content */
    #gh-head .gh-head-actions,
    #gh-head .gh-head-menu {
        display: none;
    }
    /* Open the menu */
    .gh-head-open {
        overflow: hidden;
        height: 100vh;
    }
    .gh-head-open #gh-head {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3999999;
        overflow-y: scroll;
    }
    .gh-head-open #gh-head .gh-head-inner {
        grid-template-rows: auto 1fr auto;
    }
    .gh-head-open #gh-head .gh-head-actions,
    .gh-head-open #gh-head .gh-head-menu {
        display: flex;
    }
  }

  @media (max-width: 600px) {
    #gh-head .gh-head-menu {
        font-size: 6vmin;
    }
  }

  .home-template .gh-head {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2000;
  }

  .home-template .gh-head.has-cover {
    background: transparent;
  }

  .home-template.gh-head-open .gh-head {
    background: var(--ghost-accent-color);
  }

  .home-template .gh-head-logo {
    display: none;
  }
  .home-template .gh-head-menu {
    margin-left: -2.5vmin;
  }


  /* 6. Post Feed
  /* ---------------------------------------------------------- */

  .post-feed {
    position: relative;
    display: grid;
    grid-gap: 4vmin;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 4vmin 0;
  }

  @media (max-width: 1000px) {
    .post-feed {
        grid-template-columns: 1fr 1fr;
    }
  }
  @media (max-width: 700px) {
    .post-feed {
        grid-template-columns: 1fr;
        grid-gap: 40px;
    }
  }

  .post-card {
    position: relative;
    flex: 1 1 301px;
    display: flex;
    flex-direction: column;
    min-height: 220px;
    background-size: cover;
    word-break: break-word;
  }

  .post-card-image-link {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 3px;
  }

  .post-card-image {
    width: 100%;
    height: 200px;
    background: var(--color-lightgrey) no-repeat center center;

    object-fit: cover;
  }

  .post-card-content-link {
    position: relative;
    display: block;
    color: var(--color-darkgrey);
  }

  .post-card-content-link:hover {
    text-decoration: none;
  }

  .post-card-header {
    margin: 20px 0 0;
  }

  .post-feed .no-image .post-card-content-link {
    padding: 0;
  }

  .no-image .post-card-header {
    margin-top: 0;
  }

  .post-card-primary-tag {
    margin: 0 0 0.2em;
    color: var(--ghost-accent-color);
    font-size: 1.2em;
    font-weight: 500;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  .post-card-title {
    margin: 0 0 0.4em;
    font-size: 2.4em;
    transition: color 0.2s ease-in-out;
  }

  .no-image .post-card-title {
    margin-top: 0;
  }

  .post-card-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .post-card-excerpt {
    max-width: 56em;
    color: var(--color-midgrey);
  }

  .post-card-excerpt p {
    font-size: 1.6em;
    margin-bottom: 1em;
    display: -webkit-box;
    overflow-y: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  .post-card-meta {
    display: flex;
    align-items: center;
    padding: 0;
  }

  .author-profile-image,
  .avatar-wrapper {
    display: block;
    width: 100%;
    height: 100%;
    background: var(--color-lightgrey);
    border-radius: 100%;

    object-fit: cover;
  }

  .post-card-meta .profile-image-wrapper,
  .post-card-meta .avatar-wrapper {
    position: relative;
  }

  .author-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 4px;
    padding: 0;
    list-style: none;
  }

  .author-list-item {
    position: relative;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
  }

  .static-avatar {
    display: block;
    overflow: hidden;
    margin: 0 0 0 -6px;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    box-shadow: 0 0 0 1px rgba(255,255,255,0.2);
  }

  .post-card-byline-content {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    margin: 0 0 0 8px;
    color: var(--color-midgrey);
    font-size: 1.4em;
    line-height: 1.2em;
    font-weight: 400;
  }

  .post-card-byline-content span {
    margin: 0;
  }

  .post-card-byline-content a {
    color: var(--color-darkgrey);
    font-weight: 600;
  }

  .post-card-byline-date {
    font-size: 1.3em;
    line-height: 1.5em;
  }

  .post-card-byline-date .bull {
    display: inline-block;
    margin: 0 2px;
    opacity: 0.6;
  }

  .single-author-byline {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    color: var(--color-midgrey);
    font-size: 1.3em;
    line-height: 1.4em;
    font-weight: 500;
  }

  .single-author {
    display: flex;
    align-items: center;
  }

  .single-author .static-avatar {
    margin-left: -2px;
  }

  .single-author-name {
    display: inline-block;
  }

  /* Special Styling for home page grid (below):

  The first post in the list is styled to be bigger than the others and take over
  the full width of the grid to give it more emphasis. Wrapped in a media query to
  make sure this only happens on large viewports / desktop-ish devices.

  */

  @media (min-width: 1001px) {
    .post-card-large {
        grid-column: 1 / span 3;
        display: grid;
        grid-gap: 4vmin;
        grid-template-columns: 1fr 1fr 1fr;
        min-height: 280px;
        border-top: 0;
    }

    .post-card-large:not(.no-image) .post-card-header {
        margin-top: 0;
    }

    .post-card-large .post-card-image-link {
        position: relative;
        grid-column: 1 / span 2;
        margin-bottom: 0;
        min-height: 380px;
    }

    .post-card-large .post-card-image {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .post-card-large .post-card-content {
        justify-content: center;
    }

    .post-card-large .post-card-title {
        margin-top: 0;
        font-size: 3.2em;
    }

    .post-card-large .post-card-excerpt p {
        margin-bottom: 1.5em;
        font-size: 1.7em;
        line-height: 1.55em;
        -webkit-line-clamp: 8;
    }
  }


  @media (max-width: 500px) {
    .post-card-title {
        font-size: 1.9em;
    }

    .post-card-excerpt {
        font-size: 1.6em;
    }
  }


  /* 7. Single Post
  /* ---------------------------------------------------------- */

  .article {
    padding: 8vmin 0;
    word-break: break-word;
  }

  .article-header {
    padding: 0 0 6vmin 0;
  }

  .article-tag {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 0.5em;
    color: var(--color-midgrey);
    font-size: 1.3em;
    line-height: 1.4em;
    letter-spacing: 0.02em;
    font-weight: 600;
    text-transform: uppercase;
  }

  .article-tag a {
    color: var(--ghost-accent-color);
  }

  .article-title {
    color: var(--color-darkgrey);
  }

  .article-excerpt {
    margin: 0 0 1em;
    font-size: 2em;
    line-height: 1.4em;
    opacity: 0.6;
  }

  .gh-canvas .article-image {
    grid-column: wide-start / wide-end;
    width: 100%;
    margin: 6vmin 0 0;
  }

  .gh-canvas .article-image img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  @media (max-width: 600px) {
    .article-excerpt {
        font-size: 1.8em;
    }
  }

  /* -------- */

  /* Content grid
  /* ---------------------------------------------------------- */

  /* Canvas creates a multi-column, centered grid which the post
  is laid out on top of. Canvas just defines the grid, we don't
  use it for applying any other styles. */

  .gh-canvas {
    display: grid;
    grid-template-columns:
        [full-start]
        minmax(4vmin, auto)
            [wide-start]
            minmax(auto, 240px)
                [main-start]
                    min(720px, calc(100% - 8vw))
                [main-end]
            minmax(auto, 240px)
            [wide-end]
        minmax(4vmin, auto)
        [full-end]
    ;
  }

  .gh-canvas > * {
    grid-column: main-start / main-end;
  }

  .kg-width-wide {
    grid-column: wide-start / wide-end;
  }

  .kg-width-full {
    grid-column: full-start / full-end;
  }

  .kg-width-full img {
    width: 100%;
  }


  /* Content
  /* ---------------------------------------------------------- */

  /* Content refers to styling all page and post content that is
  created within the Ghost editor. The main content handles
  headings, text, images and lists. We deal with cards lower down. */

  /* Default vertical spacing */
  .gh-content > * + * {
    margin-top: 4vmin;
    margin-bottom: 0;
  }

  /* [id] represents all headings h1-h6, reset all margins */
  .gh-content > [id] {
    margin: 0;
    color: var(--color-darkgrey);
  }

  /* Add back a top margin to all headings, unless a heading
  is the very first element in the post content */
  .gh-content > [id]:not(:first-child) {
    margin: 2em 0 0;
  }

  /* Add a small margin between a heading and anything after it */
  .gh-content > [id] + * {
    margin-top: 1.5em !important;
  }

  /* A larger margin before/after HRs and blockquotes */
  .gh-content > hr,
  .gh-content > blockquote {
    position: relative;
    margin-top: 6vmin;
  }
  .gh-content > hr + *,
  .gh-content > blockquote + * {
    margin-top: 6vmin !important;
  }

  /* Now the content typography styles */
  .gh-content a {
    color: var(--ghost-accent-color);
    text-decoration: underline;
    word-break: break-word;
  }

  .gh-content > blockquote,
  .gh-content > ol,
  .gh-content > ul,
  .gh-content > dl,
  .gh-content > p {
    font-family: var(--font-serif);
    font-weight: 400;
    font-size: 2.1em;
    line-height: 1.6em;
  }

  .gh-content > ul,
  .gh-content > ol,
  .gh-content > dl {
    padding-left: 1.9em;
  }

  .gh-content > blockquote {
    position: relative;
    font-style: italic;
    padding: 0;
  }

  .gh-content > blockquote::before {
    content: "";
    position: absolute;
    left: -1.5em;
    top: 0;
    bottom: 0;
    width: 0.3em;
    background: var(--ghost-accent-color);
  }

  .gh-content :not(pre) > code {
    vertical-align: middle;
    padding: 0.15em 0.4em 0.15em;
    border: #e1eaef 1px solid;
    font-weight: 400 !important;
    font-size: 0.9em;
    line-height: 1em;
    color: #15171A;
    background: #f0f6f9;
    border-radius: 0.25em;
  }

  .gh-content pre {
    overflow: auto;
    padding: 16px 20px;
    color: var(--color-wash);
    font-size: 1.4em;
    line-height: 1.5em;
    background: var(--color-darkgrey);
    border-radius: 5px;
    box-shadow: 0 2px 6px -2px rgba(0,0,0,.1), 0 0 1px rgba(0,0,0,.4);
  }

  @media (max-width: 650px) {
    .gh-content blockquote,
    .gh-content ol,
    .gh-content ul,
    .gh-content dl,
    .gh-content p {
        font-size: 1.7em;
    }

    .gh-content blockquote::before {
        left: -4vmin;
    }
  }


  /* Cards
  /* ---------------------------------------------------------- */

  /* Cards are dynamic blocks of content which appear within Ghost
  posts, for example: embedded videos, tweets, galleries, or
  specially styled bookmark links. We add extra styling here to
  make sure they look good, and are given a bit of extra spacing. */

  /* Add extra margin before/after any cards,
  except for when immediately preceeded by a heading */
  .gh-content :not(.kg-card):not([id]) + .kg-card {
    margin-top: 6vmin;
    margin-bottom: 0;
  }
  .gh-content .kg-card + :not(.kg-card) {
    margin-top: 6vmin;
    margin-bottom: 0;
  }

  /* This keeps small embeds centered */
  .kg-embed-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  /* This keeps small iamges centered */
  .kg-image-card img {
    margin: auto;
  }


  /* Captions */
  .kg-card figcaption {
    padding: 1.5em 1.5em 0;
    text-align: center;
    color: rgba(0,0,0,0.5);
    font-weight: 600;
    font-size: 1.3em;
    line-height: 1.4em;
  }
  .kg-card figcaption strong {
    color: rgba(0,0,0,0.8);
  }


  /* Highly specific styles for traditional Instagram embeds */
  iframe.instagram-media {
    margin-top: 6vmin !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 0 !important;
  }

  iframe.instagram-media + script + :not([id]) {
    margin-top: 6vmin;
  }


  /* Galleries
  /* ---------------------------------------------------------- */

  /* When there galleries are mixed with images, reduce margin
  between them, so it looks like 1 big gallery */
  .kg-image-card + .kg-gallery-card,
  .kg-gallery-card + .kg-image-card,
  .kg-gallery-card + .kg-gallery-card {
    margin-top: 0.75em;
  }

  .kg-image-card.kg-card-hascaption + .kg-gallery-card,
  .kg-gallery-card.kg-card-hascaption + .kg-image-card,
  .kg-gallery-card.kg-card-hascaption + .kg-gallery-card {
    margin-top: 1.75em;
  }

  .kg-gallery-container {
    position: relative;
  }

  .kg-gallery-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .kg-gallery-image img {
    display: block;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  .kg-gallery-row:not(:first-of-type) {
    margin: 0.75em 0 0 0;
  }

  .kg-gallery-image:not(:first-of-type) {
    margin: 0 0 0 0.75em;
  }


  /* Bookmark Cards
  /* ---------------------------------------------------------- */

  /* These are styled links with structured data, similar to a
  Twitter card. These styles roughly match what you see in the
  Ghost editor. */

  .kg-bookmark-card,
  .kg-bookmark-publisher {
    position: relative;
    width: 100%;
  }

  .kg-bookmark-container,
  .kg-bookmark-container:hover {
    display: flex;
    color: currentColor;
    font-family: var(--font-sans-serif);
    text-decoration: none !important;
    background: rgba(255,255,255,0.6);
    border-radius: 5px;
    box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.4);
    overflow: hidden;
  }

  .kg-bookmark-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
  }

  .kg-bookmark-title {
    font-size: 1.5em;
    line-height: 1.4em;
    font-weight: 600;
    color: #15171A;
  }

  .kg-bookmark-description {
    display: -webkit-box;
    font-size: 1.4em;
    line-height: 1.5em;
    margin-top: 3px;
    color: #626d79;
    font-weight: 400;
    max-height: 44px;
    overflow-y: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .kg-bookmark-metadata {
    display: flex;
    align-items: center;
    margin-top: 22px;
    width: 100%;
    color: #394047;
    font-size: 1.4em;
    font-weight: 500;
  }

  .kg-bookmark-icon {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }

  .kg-bookmark-author,
  .kg-bookmark-publisher {
    display: inline;
  }

  .kg-bookmark-publisher {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 240px;
    white-space: nowrap;
    display: block;
    line-height: 1.65em;
  }

  .kg-bookmark-metadata > span:nth-of-type(2) {
    color: #626d79;
    font-weight: 400;
  }

  .kg-bookmark-metadata > span:nth-of-type(2):before {
    content: "•";
    color: #394047;
    margin: 0 6px;
  }

  .kg-bookmark-thumbnail {
    position: relative;
    flex-grow: 1;
    min-width: 33%;
  }

  .kg-bookmark-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 4px 4px 0;
  }


  /* Card captions
  /* ---------------------------------------------------------- */

  .kg-width-full.kg-card-hascaption {
    display: grid;
    grid-template-columns: inherit;
  }

  .kg-width-wide.kg-card-hascaption img {
    grid-column: wide-start / wide-end;
  }
  .kg-width-full.kg-card-hascaption img {
    grid-column: 1 / -1;
  }

  .kg-width-full.kg-card-hascaption figcaption {
    grid-column: main-start / main-end;
  }

  .article-comments {
    margin: 6vmin 0 0 0;
  }

  /* -----old------ */

  .footnotes-sep {
    margin-bottom: 30px;
  }

  .footnotes {
    font-size: 1.5em;
  }

  .footnotes p {
    margin: 0;
  }

  .footnote-backref {
    font-size: 1.2em;
    font-weight: bold;
    text-decoration: none !important;
    box-shadow: none !important;
  }

  /* Tables */
  .post-full-content table {
    display: inline-block;
    overflow-x: auto;
    margin: 0.5em 0 2.5em;
    max-width: 100%;
    width: auto;
    border-spacing: 0;
    border-collapse: collapse;
    font-family: var(--font-sans-serif);
    font-size: 1.6em;
    white-space: nowrap;
    vertical-align: top;
  }

  .post-full-content table {
    -webkit-overflow-scrolling: touch;
    background: radial-gradient(ellipse at left, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) 0 center, radial-gradient(ellipse at right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) 100% center;
    background-attachment: scroll, scroll;
    background-size: 10px 100%, 10px 100%;
    background-repeat: no-repeat;
  }

  .post-full-content table td:first-child {
    background-image: linear-gradient(to right, rgba(255,255,255, 1) 50%, rgba(255,255,255, 0) 100%);
    background-size: 20px 100%;
    background-repeat: no-repeat;
  }

  .post-full-content table td:last-child {
    background-image: linear-gradient(to left, rgba(255,255,255, 1) 50%, rgba(255,255,255, 0) 100%);
    background-position: 100% 0;
    background-size: 20px 100%;
    background-repeat: no-repeat;
  }

  .post-full-content table th {
    color: var(--color-darkgrey);
    font-size: 1.2em;
    font-weight: 700;
    letter-spacing: 0.2px;
    text-align: left;
    text-transform: uppercase;
    background-color: var(--color-wash);
  }

  .post-full-content table th,
  .post-full-content table td {
    padding: 6px 12px;
    border: var(--color-wash) 1px solid;
  }


  /* 7.1. Post Byline
  /* ---------------------------------------------------------- */

  .article-byline {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0;
  }

  .article-byline-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .article-byline-content .author-list {
    justify-content: flex-start;
    padding: 0 12px 0 0;
  }

  .article-byline-meta {
    color: var(--color-midgrey);
    font-size: 1.4em;
    line-height: 1.2em;
  }

  .article-byline-meta h4 {
    margin: 0 0 3px;
    font-size: 1.6em;
  }

  .article-byline-meta .bull {
    display: inline-block;
    margin: 0 2px;
    opacity: 0.6;
  }

  .author-avatar {
    display: block;
    overflow: hidden;
    margin: 0 -4px;
    width: 50px;
    height: 50px;
    border: #fff 2px solid;
    border-radius: 100%;
    transition: all 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99) 700ms;
  }

  .basic-info .avatar-wrapper {
    position: relative;
    margin: 0;
    width: 60px;
    height: 60px;
    border: none;
    background: rgba(229, 239, 245, 0.1);
  }

  .basic-info .avatar-wrapper svg {
    margin: 0;
    width: 60px;
    height: 60px;
    opacity: 0.15;
  }

  .page-template .article-title {
    margin-bottom: 0;
  }


  /* 7.3. Subscribe
  /* ---------------------------------------------------------- */

  .footer-cta {
    position: relative;
    padding: 9vmin 4vmin 10vmin;
    color: #fff;
    text-align: center;
    background: var(--color-darkgrey);
  }

  /* Increases the default h2 size by 15%, for small and large screens */
  .footer-cta h2 {
    margin: 0 0 30px;
    font-size: 3.2em;
  }

  @media (max-width: 600px) {
    .footer-cta h2 {
        font-size: 2.65em;
    }
  }

  .footer-cta-button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 500px;
    padding: 5px 5px 5px 15px;
    font-size: 1.8em;
    color: var(--color-midgrey);
    background: #fff;
    border-radius: 8px;
  }

  .footer-cta-button span {
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    font-weight: 500;
    background: var(--ghost-accent-color);
    border-radius: 5px;
  }


  /* 7.4. Read more
  /* ---------------------------------------------------------- */

  .read-more-wrap {
    width: 100%;
    padding: 4vmin;
    margin: 0 auto -40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background: var(--color-darkgrey);
  }

  .read-more {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 4vmin;
  }

  .read-more .post-card-title {
    color: #fff;
    opacity: 0.8;
  }

  .read-more .post-card-excerpt {
    color: rgba(255, 255, 255, 0.6);
  }

  .read-more .post-card-byline-content a {
    color: #fff;
  }


  @media (max-width: 1000px) {
    .read-more {
        grid-template-columns: 1fr 1fr;
    }
    .read-more article:nth-child(3) {
        display: none;
    }
  }

  @media (max-width: 700px) {
    .read-more {
        grid-template-columns: 1fr;
    }
    .read-more article:nth-child(2) {
        display: none;
    }
  }


  /* 8. Author Template
  /* ---------------------------------------------------------- */

  .author-template .posts {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: 200px 1fr 1fr;
    grid-gap: 4vmin;
  }

  .author-template .posts .post-feed {
    grid-column: 2 / 4;
    grid-template-columns: 1fr 1fr;
  }

  .author-profile {
    padding: 4vmin 0;
  }

  .author-profile-content {
    height: auto;
    position: sticky;
    top: 4vmin;
    font-size: 1.4em;
    line-height:  1.4em;
  }

  .author-profile-pic {
    display: block;
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin: 0 0 2em;
    background: var(--color-lightgrey);
    border-radius: 50%;
  }

  .author-profile h1 {
    margin: 0 0 0.3em;
    font-size: 2em;
  }

  .author-profile p {
    margin: 0 0 1.5em;
    color: var(--color-midgrey);
  }

  .author-profile-location {
    margin: 0 0 1.5em;
    font-weight: 700;
  }

  .author-profile-social-link {
    display: block;
    padding: 0 0 5px;
    font-size: 1.3em;
    color: var(--color-midgrey);
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
  }

  .author-profile-social-link:hover {
    color: var(--color-darkgrey);
  }

  @media (max-width: 900px) {
    .author-template .posts .post-feed {
        grid-template-columns: 1fr;
    }
  }

  @media (max-width: 650px) {
    .author-template .posts {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
    .author-template .posts .post-feed {
        grid-column: 1 / auto;
    }
    .author-profile {
        padding-right: 0;
    }
    .author-profile-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
  }


  /* 8. Tag Template
  /* ---------------------------------------------------------- */

  .tag-template .post-card-large .post-card-image-link {
    grid-column: 2 / span 2;
    order: 2;
  }

  .tag-template .post-card-large .post-card-content {
    order: 1;
  }


  /* 9. Error Template
  /* ---------------------------------------------------------- */

  .error-content {
    padding: 14vw 4vw 6vw;
  }

  .error-message {
    padding-bottom: 10vw;
    text-align: center;
  }

  .error-code {
    margin: 0;
    color: var(--ghost-accent-color);
    font-size: 12vw;
    line-height: 1em;
    letter-spacing: -5px;
  }

  .error-description {
    margin: 0;
    color: var(--color-midgrey);
    font-size: 3.2em;
    line-height: 1.3em;
    font-weight: 400;
  }

  .error-link {
    display: inline-block;
    margin-top: 5px;
  }

  @media (min-width: 940px) {
    .error-content .post-card {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
    }
  }

  @media (max-width: 800px) {
    .error-content {
        padding-top: 24vw;
    }
    .error-code {
        font-size: 11.2em;
    }
    .error-message {
        padding-bottom: 16vw;
    }
    .error-description {
        margin: 5px 0 0 0;
        font-size: 1.8em;
    }
  }

  @media (max-width: 500px) {
    .error-content {
        padding-top: 28vw;
    }
    .error-message {
        padding-bottom: 14vw;
    }
  }


  /* 11. Site Footer
  /* ---------------------------------------------------------- */

  .site-footer {
    position: relative;
    margin: 40px 0 0 0;
    padding: 40px 4vmin 140px;
    color: #fff;
    background: var(--color-darkgrey);
  }

  .site-footer .inner {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: auto 1fr auto;
    color: rgba(255,255,255,0.7);
    font-size: 1.3em;
  }

  .site-footer .copyright a {
    color: #fff;
    letter-spacing: -0.015em;
    font-weight: 500;
  }

  .site-footer a {
    color: rgba(255,255,255,0.7);
  }

  .site-footer a:hover {
    color: rgba(255,255,255,1);
    text-decoration: none;
  }

  .site-footer-nav ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 0 20px;
    padding: 0;
    list-style: none;
  }

  .site-footer-nav li {
    display: inline-flex;
    align-items: center;
    padding: 0;
    margin: 0;
    line-height: 2em;
  }

  .site-footer-nav a {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
  }

  .site-footer-nav li:not(:first-child) a:before {
    content: "";
    display: block;
    width: 2px;
    height: 2px;
    margin: 0 10px 0 0;
    background: #fff;
    border-radius: 100%;
  }

  @media (max-width: 800px) {
    .site-footer .inner {
        max-width: 500px;
        grid-template-columns: 1fr;
        grid-gap: 0;
        text-align: center;
    }
    .site-footer .copyright,
    .site-footer .copyright a {
        color: #fff;
        font-size: 1.5em;
    }
  }


  /* 12. Dark Mode
  /* ---------------------------------------------------------- */

  /* If you prefer a dark color scheme, you can enable dark mode
  by adding the following code to the Head section of "Code Injection"
  settings inside: Ghost Admin > Settings > Advanced

  <script>document.documentElement.classList.add('dark-mode');</script>

  Or you can just edit default.hbs and add the .dark-mode class directly
  to the html tag on the very first line of the file.

  */

  html.dark-mode body {
    color: rgba(255, 255, 255, 0.75);
    background: var(--color-darkmode);
  }

  html.dark-mode img {
    opacity: 0.9;
  }

  html.dark-mode .post-card,
  html.dark-mode .post-card:hover {
    border-bottom-color: var(--color-darkmode);
  }

  html.dark-mode .post-card-byline-content a {
    color: rgba(255, 255, 255, 0.75);
  }

  html.dark-mode .post-card-byline-content a:hover {
    color: #fff;
  }

  html.dark-mode .post-card-image {
    background: var(--color-darkmode);
  }

  html.dark-mode .post-card-title {
    color: rgba(255, 255, 255, 0.85);
  }

  html.dark-mode .post-card-excerpt {
    color: var(--color-midgrey);
  }

  html.dark-mode .post-full-content {
    background: var(--color-darkmode);
  }

  html.dark-mode .article-title {
    color: rgba(255, 255, 255, 0.9);
  }

  html.dark-mode .article-excerpt {
    color: var(--color-midgrey);
  }

  html.dark-mode .post-full-image {
    background-color: var(--color-darkmode);
  }

  html.dark-mode .article-byline {
    border-top-color: var(--color-darkmode);
  }

  html.dark-mode .article-byline-meta h4 a {
    color: rgba(255, 255, 255, 0.75);
  }

  html.dark-mode .article-byline-meta h4 a:hover {
    color: #fff;
  }

  html.dark-mode .no-image .author-social-link a {
    color: rgba(255, 255, 255, 0.75);
  }

  html.dark-mode .gh-content h1,
  html.dark-mode .gh-content h2,
  html.dark-mode .gh-content h3,
  html.dark-mode .gh-content h4,
  html.dark-mode .gh-content h5,
  html.dark-mode .gh-content h6 {
    color: rgba(255, 255, 255, 0.9);
  }

  html.dark-mode .gh-content pre {
    background: var(--color-darkgrey);
  }

  html.dark-mode .gh-content :not(pre) > code {
    background: var(--color-darkgrey);
    border-color: var(--color-darkmode);
    color: var(--color-wash);
  }

  html.dark-mode .post-full-content a {
    color: #fff;
    box-shadow: inset 0 -1px 0 #fff;
  }

  html.dark-mode .post-full-content strong {
    color: #fff;
  }

  html.dark-mode .post-full-content em {
    color: #fff;
  }

  html.dark-mode .post-full-content code {
    color: #fff;
    background: #000;
  }

  html.dark-mode hr {
    border-top-color: var(--color-darkmode);
  }

  html.dark-mode .post-full-content hr:after {
    background: var(--color-darkmode);
    box-shadow: var(--color-darkmode) 0 0 0 5px;
  }

  html.dark-mode .gh-content figcaption {
    color: rgba(255, 255, 255, 0.6);
  }

  html.dark-mode .post-full-content table td:first-child {
    background-image: linear-gradient(to right, var(--color-darkmode) 50%, var(--color-darkmode) 100%);
  }

  html.dark-mode .post-full-content table td:last-child {
    background-image: linear-gradient(to left, var(--color-darkmode) 50%, var(--color-darkmode) 100%);
  }

  html.dark-mode .post-full-content table th {
    color: rgba(255, 255, 255, 0.85);
    background-color: var(--color-darkmode);
  }

  html.dark-mode .post-full-content table th,
  html.dark-mode .post-full-content table td {
    border: var(--color-darkmode) 1px solid;
  }

  html.dark-mode .post-full-content .kg-bookmark-container,
  html.dark-mode .post-full-content .kg-bookmark-container:hover {
    color: rgba(255, 255, 255, 0.75);
    box-shadow: 0 0 1px rgba(255,255,255,0.9);
  }

  html.dark-mode .post-full-content input {
    color: var(--color-midgrey);
  }

  html.dark-mode .kg-bookmark-title {
    color: #fff;
  }

  html.dark-mode .kg-bookmark-description {
    color: rgba(255, 255, 255, 0.75);
  }

  html.dark-mode .kg-bookmark-metadata {
    color: rgba(255, 255, 255, 0.75);
  }

  html.dark-mode .site-archive-header .no-image {
    color: rgba(255, 255, 255, 0.9);
    background: var(--color-darkmode);
  }

  html.dark-mode .subscribe-form {
    border: none;
    background: linear-gradient(var(--color-darkmode)), var(--color-darkmode);
  }

  html.dark-mode .subscribe-form-title {
    color: rgba(255, 255, 255, 0.9);
  }

  html.dark-mode .subscribe-form p {
    color: rgba(255, 255, 255, 0.7);
  }

  html.dark-mode .subscribe-email {
    border-color: var(--color-darkmode);
    color: rgba(255, 255, 255, 0.9);
    background: var(--color-darkmode);
  }

  html.dark-mode .subscribe-email:focus {
    border-color: var(--color-darkmode);
  }

  html.dark-mode .subscribe-form button {
    opacity: 0.9;
  }

  html.dark-mode .subscribe-form .invalid .message-error,
  html.dark-mode .subscribe-form .error .message-error {
    color: var(--color-red);
  }

  html.dark-mode .subscribe-form .success .message-success {
    color: var(--color-green);
  }

  /*

  Hey! You reached the end.

  Hope you enjoyed this CSS file, if you have any suggestions
  for improvements that might be useful for everyone who uses
  this theme, you can find the open source repository for it
  here: https://github.com/tryghost/casper

  Or, if you've just scrolled all the way to the bottom of the
  file to add some of your own styles. Well, you've come to
  the right place. Onward!

  */

}
