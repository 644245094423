
@import '../../styles/index.scss';

$drawerWidth: 210px;
$drawerWidthIcon: 55px;

.LayoutContainer {
  @include flex(row, center, center, wrap);
  background-color: $grayColor7;
  margin-left: $drawerWidthIcon;
  z-index: $zIndexLevel1K;
}

.LayoutLinkTo {
  width: $drawerWidth;
  text-decoration: none;
  color: $fontColor1;
}

.LayoutLinkToListItem {
  width: 100%;
  min-height: 46px;
  height: 46px;
  @include flex(row, flex-start, center, nowrap);
  margin-bottom: $step1;  
}

.LayoutLinkToIcon, .LayoutAvatarImgContainer {
  text-align: center;
  padding: 5px;
  max-width: $drawerWidthIcon;
  width: $drawerWidthIcon;
  height: 100%;  
  @include flex(column, center, center, nowrap);
}

.LayoutLinkToIcon {
  border-radius: 0px 5px 5px 0px;
  &:hover {
    background-color: $grayColor5;
  }
}

.LayoutAvatarBtnImg {
  padding-left: 5px;
  background-color: transparent;
  border-style: none;
  cursor: pointer;
}


.LayoutLinkToTextContainer, .LayoutAvatarTextContainer {  
  box-sizing: border-box;
  max-width: $drawerWidth - $drawerWidthIcon;
  width: $drawerWidth - $drawerWidthIcon;
}

.LayoutLinkToText, .LayoutAvatarText {
  @include flex(column, center, flex-start, nowrap);
  padding: 0px 14px;
  color: $fontColor1;
  font-size: 1rem;
  word-break: break-all;    
}

.LayoutAvatarNameContainer {
  @include flex(row, space-between, center, wrap);
  @include border(solid, 0px 0px 1px 0px, 0px, transparent);
  &:hover {
    cursor: pointer;
    @include border(solid, 0px 0px 1px 0px, 0px, $actionColor1);
    span {
      &:hover {
        color: $actionColor1;
      }
    }
  }
}

.LayoutAvatarNameText {
  margin-right: 5px; // todo: acomodar esto!
}

.LayoutLinkToSubText, .LayoutAvatarSubText {
  color:$fontColor2;
  margin-right: $step1;
  font-size: 1rem;
}

.LayoutLinkToSelected {
  .LayoutLinkToIcon {
    background-color: $actionColor1;    
  }
  .LayoutLinkToText {
    color: $actionColor1;
  }
}

.LayoutChildrenContainer { 
  min-height: 100vh;
  width: 100%;
}

.LayoutAppBarLeftIconsContainer {
  @include flex(row, space-between, center, wrap);  
  width: 100%;
  div {
    @include flex(row, space-between, center, wrap);  
  }
}

.LayoutListContainer {
  @include flex(column, space-between, flex-start, wrap);  
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

.LayoutAvatarSubTextContainer {
  @include flex(row, center, center, wrap);
}

.LayoutAppBarLogo {
  background-image: url('../../assets/img/logo_sensie_white.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-width: 1px;
  width: 91px;
  height: 32.78px;
}

.LayoutDrawerClose {
  .LayoutLinkToTextContainer, .LayoutAvatarTextContainer {
    display: none;
  }
}
