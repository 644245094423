@font-face {
  font-family: 'Biotif';
  src: url('../fonts/Biotif/Biotif-BlackItalic.woff2') format('woff2'),
      url('../fonts/Biotif/Biotif-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('../fonts/Biotif/Biotif-Bold.woff2') format('woff2'),
      url('../fonts/Biotif/Biotif-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('../fonts/Biotif/Biotif-Black.woff2') format('woff2'),
      url('../fonts/Biotif/Biotif-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('../fonts/Biotif/Biotif-BoldItalic.woff2') format('woff2'),
      url('../fonts/Biotif/Biotif-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif Book';
  src: url('../fonts/Biotif/Biotif-BookItalic.woff2') format('woff2'),
      url('../fonts/Biotif/Biotif-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif Book';
  src: url('../fonts/Biotif/Biotif-Book.woff2') format('woff2'),
      url('../fonts/Biotif/Biotif-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('../fonts/Biotif/Biotif-ExtraBold.woff2') format('woff2'),
      url('../fonts/Biotif/Biotif-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('../fonts/Biotif/Biotif-ExtraBoldItalic.woff2') format('woff2'),
      url('../fonts/Biotif/Biotif-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('../fonts/Biotif/Biotif-Light.woff2') format('woff2'),
      url('../fonts/Biotif/Biotif-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('../fonts/Biotif/Biotif-Medium.woff2') format('woff2'),
      url('../fonts/Biotif/Biotif-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('../fonts/Biotif/Biotif-LightItalic.woff2') format('woff2'),
      url('../fonts/Biotif/Biotif-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('../fonts/Biotif/Biotif-MediumItalic.woff2') format('woff2'),
      url('../fonts/Biotif/Biotif-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('../fonts/Biotif/Biotif-RegularItalic.woff2') format('woff2'),
      url('../fonts/Biotif/Biotif-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif';
  src: url('../fonts/Biotif/Biotif-Regular.woff2') format('woff2'),
      url('../fonts/Biotif/Biotif-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif Semi';
  src: url('../fonts/Biotif/Biotif-SemiBold.woff2') format('woff2'),
      url('../fonts/Biotif/Biotif-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Biotif Semi';
  src: url('../fonts/Biotif/Biotif-SemiBoldItalic.woff2') format('woff2'),
      url('../fonts/Biotif/Biotif-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

