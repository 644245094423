@import '../../styles/index.scss';

.ModalComponentContainer {
  button {
    background-color: transparent;
    border-style: none;
  }
}

.ModalContainer {
  @include flex(row, center, center, nowrap);  
}

.ModalComponentBodyContainer {
  width: 30vw;
  background-color: $grayColor8;
  padding: 20px;
}

.ModalComponentHeader {
  width: 100%;
  padding: 10px 0px;
  @include flex(row, space-between, center, nowrap);
  span {
    color: $fontColor1;
    font-size: 1.5rem;
  }
  button {
    border-style: none;
    background-color: transparent;
    opacity: 0.9;
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }  
}