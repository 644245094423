@import '../../styles/index.scss';

.MultipleSelectCheckboxContainer {
  @include flex(row, center, center, nowrap);
  position: relative;
  button {
    width: 100%;
    @include flex(row, space-between, center, nowrap);
    span {
      color: $fontColor1;
      margin-right: 10px;
    }
  }
}

.MultipleSelectCheckboxMenuItem {
  .MultipleSelectCheckboxMenuItemCheckbox {
    color: $actionColor1 !important;
  }
  div {
    padding-right: 10px;
  }
}

.MultipleSelectCheckboxButton {
  opacity: 1;
  color: $fontColor1;
  background-color: transparent;
  @include border(none, 0px, 0px, transparent);
  &:hover, &:focus, &:active {
    @include border(none, 0px, 0px, transparent);
  }
}

.MultipleSelectCheckboxButtonDisabled {
  opacity: 0.8;
}
