.MuiInput-underline::before, .MuiInput-underline::after {
  border-style: none !important; 
}

.MuiInput-root {
  z-index: $zIndexLevel998 !important;
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
}

.MuiList-padding {
  box-sizing: border-box !important;
}

.MuiInputBase-root {
  svg {
    display: none !important;
  }
}

.MuiBadge-colorPrimary {
  background-color: $actionColor1 !important;
}

.MuiListItem-gutters {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.MuiPagination-ul {
  .Mui-selected {
    background-color: $actionColor1 !important;
  }
}

.MuiPaginationItem-root, .MuiPaginationItem-ellipsis {
  color: $fontColor1 !important;
}