@import '../../styles/index.scss';

.ClientSnapshotContainer {
  width: 100%;
  margin-top: 10px;
}

.ClientSnapshotBarChartContainer {
  background-color: $grayColor6;
  padding: 10px;
  width: 100%;
  @include border(solid, 1px, 5px, transparent);
  .ClientSnapshotBarChartHeader {
    @include flex(row, space-between, center, nowrap);
    div {
      @include flex(row, flex-start, center, nowrap);
      h4 {
        margin-left: 10px;
        font-size: 1rem;
      }
      &:hover {
        h4 {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

.ClientSnapshotChartContainer {
  height: 200px;
}

.ClientSnapshotFooter {
  width: 100%;
  @include flex(row, flex-end, center, nowrap);
  padding: 5px 0px;
}