@import '../../styles/index.scss';

$size: 11vh;

.PieChartContainer {
  @include flex(column, center, center, nowrap);
  @include border(none, 0px, 5px, transparent);
  background-color: $grayColor5;
  padding: 5px;
  width: 100%;
  height: 95%;
  margin: 10px;
  h4 {
    color: $grayColor2;
    font-size: 1.25rem;
    margin-bottom: 5px;
  }
}

.PieChartDataContainer {
  min-width: $size;
  min-height: $size;
  max-width: $size;
  max-height: $size;
  color: $fontColor1;
  text-align: center;
  position: relative;
}

.PieChartCenterText {
  position: absolute;
  top: 0;
  right:20px;
  bottom: 0;
  left: 20px;
  font-size: 1rem;
  color: $fontColor1;
  text-align: center;
  pointer-events: none;
  @include flex(column, center, center, nowrap);
}