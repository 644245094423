@import '../../styles/index.scss';

.TrackAffirmationsContainer {
  @include G50Container();   
}

.TrackAffirmationsContainerHeight {
  @include heightGrid();
}

.TrackAffirmationsContainerHeightMin {
  @include heightGridMin();
}

.TrackAffirmationsBodyContainer {  
  @include G50BodyContainer();
}

.TrackAffirmationsHeaderContainer { 
  @include G50HeaderContainer();  
}

.TrackAffirmationsHeaderTitleContainer {
  @include G50HeaderTitleContainer();   
}

.TrackAffirmationsHeaderBtnContainer {
  button {
    @include border(solid, 2px, 5px, $grayColor4);
    background-color: $grayColor5;
    padding: 10px 15px;
    font-size: 1rem;
    color: $fontColor1;
    &:hover {
      @include border(solid, 2px, 5px, $actionColor3);
      cursor: pointer;
    }
  }
}

.TrackAffirmationsOptionsContainer {
  width: 100%;  
  height: 85%;
  max-height: 85%;
}