@import '../../styles/index.scss';

.IconChartContainer {
  max-width: 100%;  
  max-height: 100%;
  @include flex(column, center, flex-start, nowrap);
  padding: 10px 15px;  
  border-radius: 5px;
  width: 100%;
  height: 100%;
  margin: 0 5px;
  span {
    color: $grayColor2;
    font-size: 1.25rem;
  }
  div {
    width: 100%;
    @include flex(row, flex-start, center, nowrap);
    span {
      color: $fontColor1;
      font-weight: bold;
      font-size: 1.25rem;
      margin-right: 2.5px;
    }
  }
}

.IconChartBackground1 {
  background-color: $grayColor7;
}

.IconChartBackground2 {
  background-color: transparent;
}